import React, { useState, useEffect } from 'react'

import './AdminPage.css'

function AdminPage (props) {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [validated, setValidated] = useState(false)
  const [invalid, setInvalid] = useState(false)

  const [portErrors, setPortErrors] = useState([])

  useEffect(() => {
    if (validated) {
      const fetchErrors = async () => {
        const response = await fetch('/admin/errors', {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            username: username,
            password: password,
          })
        })
        const json = await response.json()
        setPortErrors(json)
      }
      fetchErrors()
    }
    // eslint-disable-next-line
  }, [validated])

  const handleSubmit = async event => {
    event.preventDefault()
    try {
      const res = await fetch('/admin/login', {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          username: username,
          password: password,
        })
      })

      const body = await res.json()

      if (res.status !== 200 && body.type === 'admin') {
        setInvalid(true)
      } else {
        setInvalid(false)
        setValidated(true)
      }
    } catch (error) {
      console.log('ERRORED', error)
      alert('Login failed for unknown reasons.')
    }
  }
  if (!validated) {
    return (
      <div className="login-page">
        <header>Admin Page</header>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="username">Username:</label>
            <input type="text" id="username" name="username" required
                   onChange={event => setUsername(event.target.value)}/>
          </div>
          <div>
            <label htmlFor="pass">Password:</label>
            <input type="password" id="pass" name="password" required
                   onChange={event => setPassword(event.target.value)}/>
          </div>
          <input type="submit" value="Sign in"/>
        </form>
        {invalid && <div>Invalid username or password!</div>}
      </div>
    )
  } else {
    return (
      <div className="admin-information-page">
        {
          portErrors ?
            <div>
              <div>
                Errors while porting:
              </div>
              <div>
                {portErrors.map(data =>
                  <div className="admin-errors" key={data.id + data.message + data.type}>
                    <div className="admin-errors-row">
                      <div>ID:</div>
                      <div>{data.id}</div>
                    </div>
                    <div className="admin-errors-row">
                      <div>Row Number:</div>
                      <div>{data.row}</div>
                    </div>
                    <div className="admin-errors-row">
                      <div>Type:</div>
                      <div>{data.type}</div>
                    </div>
                    <div className="admin-errors-row">
                      <div>Message:</div>
                      <div>{data.message}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            :
            'No errors occured while porting the data!'
        }
      </div>
    )
  }
}

export default AdminPage

