import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Media from 'react-media'

import TopScrollLink from '../topscrolllink/TopScrollLink'
import './HoverCard.css'
import classnames from 'classnames'

let deselect = null;

export default function HoverCard (props) {

  const [hover, setHover] = useState(false)

  const innerCard = (isMobile) => {
    return (
      <div style={{
        height: props.size.height,
        width: props.size.width
      }}
           className="hover-card" onMouseEnter={isMobile ? null : setHover.bind(this, true)}
           onMouseLeave={isMobile ? null : setHover.bind(this, false)}
      >
        <div className={classnames('hovercard-card')}>
          <div className={classnames('color-block', { 'color-block-hover': hover })}/>
          <img className={classnames('hovercard-image', { 'hovercard-image-hover': hover })}
               src={props.imageURL} alt=""/>
          <p className={classnames('center-text', { 'fade-out': !hover })}>{props.program_name}</p>
        </div>
        <div className="card-bottom">
          <p className={classnames('bottom-text')}>{props.org_name}</p>
          <p
            className={classnames('bottom-text', 'allow-overflow-text')}>{props.city && `${props.city}, `}{props.country}</p>
        </div>
      </div>
    )
  }

  const redirect = () => {
    if (hover) {
      window.location.href = `/org/${props.id}`
    } else {
      if (deselect) {
        deselect()
      }
      deselect = setHover.bind(this, false)
      setHover(true)
    }
  }

  return (
    <Media queries={{
      mobile: '(max-width: 499px)',
    }}>
      {matches => (<React.Fragment>{!matches.mobile ?
        <TopScrollLink to={`/org/${props.id}`} style={{ textDecoration: 'none' }} alt=""
              className="outer-hovercard">
          {innerCard(matches.mobile)}
        </TopScrollLink> :
        <button onClick={redirect} style={{ textDecoration: 'none' }} alt="" className="outer-hovercard">
          {innerCard(matches.mobile)}
        </button>
      }</React.Fragment>)
      }
    </Media>
  )
}

HoverCard.propTypes = {
  imageURL: PropTypes.string.isRequired,
  program_name: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  city: PropTypes.string,
  org_name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  size: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
}
