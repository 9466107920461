import React, { useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import { Switch, Route, useParams } from 'react-router-dom'

import FilterBar from '../../components/filterbar/FilterBar'

import './SearchPage.css'
import HoverCardContainer from '../../components/cards/HoverCardContainer'
import SearchBar from '../../components/SearchBar'

const REGION_OPTIONS = [
  {
    value: 'africa',
    label: 'AFRICA'
  },
  {
    value: 'asia',
    label: 'ASIA'
  },
  {
    value: 'south_america',
    label: 'SOUTH AMERICA'
  },
  {
    value: 'central_america',
    label: 'NORTH + CENTRAL AMERICA'
  },
  {
    value: 'europe',
    label: 'EUROPE'
  },
]

const ACTIVITY_OPTIONS = [
  {
    value: 'conservation',
    label: 'CONSERVATION'
  },
  {
    value: 'education',
    label: 'CHILDREN + EDUCATION'
  },
  {
    value: 'community',
    label: 'COMMUNITY'
  },
  {
    value: 'medical',
    label: 'MEDICAL'
  },
]

const FILTER_IN_OPTIONS = [
  {
    label: 'On site accomodation',
    value: 'on_site_accomodation',
  },
  {
    label: '3 meals per day',
    value: 'three_meals',
  },
  {
    label: '1+ meals per day',
    value: 'one_meal',
  },
  {
    label: 'Airport transportation',
    value: 'airport_transport',
  },
  {
    label: 'Female only',
    value: 'female_only',
  },
  {
    label: 'Family friendly',
    value: 'family_friendly',
  }
]

const FILTER_OUT_OPTIONS = [
  {
    label: 'No additional language requirement',
    value: 'language_reqs',
  },
  {
    label: 'No medical expertise',
    value: 'medical_expertise',
  },
  {
    label: 'No teaching expertise',
    value: 'teaching_expertise',
  },
]

/**
 * Displays the Search Page based on the URL: /search/:type/:category
 * @param props
 * @constructor
 */
export default function SearchPage (props) {
  return (
    <Switch>
      <Route path="/search/:type/:category">
        <ParseSearchURL/>
      </Route>
      <Route>
        <GenericSearchPage/>
      </Route>
    </Switch>
  )

}

function ParseSearchURL (props) {
  let { type, category } = useParams()
  let found = false
  let title = ''
  switch (type) {
    case 'all':
      return (
        <GenericSearchPage/>
      )
    case 'region':
      for (let i = 0; i < REGION_OPTIONS.length; i++) {
        const option = REGION_OPTIONS[i]
        if (option.value === category) {
          found = true
          title = option.label
          break
        }
      }

      if (found) {
        return (
          <GenericSearchPage pageFilterType={type} pageFilterSelection={category}
                             mainFilterType="activity"
                             mainOptions={ACTIVITY_OPTIONS}
                             title={title}
          />
        )
      } else {
        return <GenericSearchPage/>
      }
    case 'activity':
      for (let i = 0; i < ACTIVITY_OPTIONS.length; i++) {
        const option = ACTIVITY_OPTIONS[i]
        if (option.value === category) {
          found = true
          title = option.label
          break
        }
      }
      if (found) {
        return (
          <GenericSearchPage pageFilterType={type} pageFilterSelection={category}
                             mainFilterType="region"
                             mainOptions={REGION_OPTIONS}
                             title={title}
          />
        )
      } else {
        return <GenericSearchPage/>
      }
    default:
      return (
        <GenericSearchPage/>
      )
  }
}

/**
 * A parent component for the three different types of search pages
 * @param props
 * @returns {*}
 * @constructor
 */
function GenericSearchPage (props) {
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedFilterInOptions, setSelectedFilterInOptions] = useState({})
  const [selectedFilterOutOptions, setSelectedFilterOutOptions] = useState({})
  const [keywords, setKeywords] = useState('')
  const [age, setAge] = useState(20)
  const [duration, setDuration] = useState([2, 8])

  let query = {}
  if (props.pageFilterType) {
    query[props.pageFilterType] = props.pageFilterSelection
  }
  query['age'] = age
  query['duration'] = duration
  if (selectedOption) {
    query[props.mainFilterType] = selectedOption
  }
  for (let [key, value] of Object.entries(selectedFilterInOptions)) {
    if (value) {
      query[key] = true
    }
  }
  for (let [key, value] of Object.entries(selectedFilterOutOptions)) {
    if (value) {
      query[key] = false
    }
  }

  let title = props.title

  return (
    <div className="search-page">
      <div className="search-header">
        <h1 className="search-title">{title ? title : 'SEE ALL PROGRAMS'}</h1>
        <p>Each individual carries his or her own set of unique talents and past experiences.
          Volunteering has the potential to bring out your prior strengths in a context where it is
          not only valued, but needed. And, even if you don’t think you have any particular skills,
          your attitude and volunteering approach can make all the difference in what kind of
          program will best suit you. As such, some programs may require prior experience,
          background checks, or their own external application process.</p>
        <p>
          All programs pages on The Campanile Project will be linked to their own websites for 100%
          full disclosure. Our on-site friends at their respective programs are most qualified to
          answer any additional questions, and we know they’ll be the best people to get in touch
          with. <strong>For the most ethical and streamlined approach, interested volunteers should directly
          contact programs through their own websites or through the emails provided below.</strong>
        </p>
      </div>
      <FilterBar
        age={age}
        setAge={setAge}
        duration={duration}
        setDuration={setDuration}
      />
      <div className="search-page-search-bar">
        <SearchBar onSubmit={value => {setKeywords(value)}} value={keywords}/>
      </div>
      <div className="bottom-search-section">
        <Selector options={props.mainOptions} filterOptions={FILTER_IN_OPTIONS}
                  filterOutOptions={FILTER_OUT_OPTIONS}
                  selectedFilterOptions={selectedFilterInOptions}
                  setSelectedFilterOptions={setSelectedFilterInOptions}
                  selectedFilterOutOptions={selectedFilterOutOptions}
                  setSelectedFilterOutOptions={setSelectedFilterOutOptions}
                  setSelectedOption={setSelectedOption} selectedOption={selectedOption}
        />
        <HoverCardContainer
          searchBody={{
            query: query,
            keywords,
          }}
        />
      </div>
    </div>
  )
}

GenericSearchPage.propTypes = {
  pageFilterType: PropTypes.string,
  pageFilterSelection: PropTypes.string,
  mainFilterType: PropTypes.string,
  mainOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })),
  title: PropTypes.string,
}

function Selector (props) {

  let options = []
  let filterOptions = []

  if (props.options && props.options.length > 0) {
    for (let option of props.options) {
      options.push(
        <Option
          key={option.value}
          {...props}
          selected={option.value === props.selectedOption}
          label={option.label}
          value={option.value}
          color="primary"
        />)
    }
  }

  if (props.filterOptions && props.filterOptions.length > 0) {
    for (let option of props.filterOptions) {
      filterOptions.push(
        <FilterOption
          key={option.value}
          {...props}
          label={option.label}
          value={option.value}
        />)
    }
  }

  if (props.filterOutOptions && props.filterOutOptions.length > 0) {
    for (let option of props.filterOutOptions) {
      filterOptions.push(
        <FilterOption
          key={option.value}
          setSelectedFilterOptions={props.setSelectedFilterOutOptions}
          selectedFilterOptions={props.selectedFilterOutOptions}
          label={option.label}
          value={option.value}
        />)
    }
  }

  return (
    <div className="search-page-selector">
      {props.options &&
      <React.Fragment>
        <div className="search-page-options">
          {options}
        </div>
        <hr className="search-selector-separator"/>
      </React.Fragment>
      }
      {(props.filterOptions || props.filterOutOptions) &&
      <div className="other-filters" style={{ marginTop: props.options ? '10px' : '30px' }}>
        {filterOptions}
      </div>
      }
    </div>
  )
}

Selector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  selectedOption: PropTypes.string,
  setSelectedOption: PropTypes.func.isRequired,
  filterOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  setSelectedFilterOptions: PropTypes.func.isRequired,
  selectedFilterOptions: PropTypes.object.isRequired,
  filterOutOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  setSelectedFilterOutOptions: PropTypes.func.isRequired,
  selectedFilterOutOptions: PropTypes.object.isRequired,
}

function Option (props) {

  const handleClick = () => {
    props.setSelectedOption(props.selected ? null : props.value)
  }

  return (
    <div onClick={handleClick}
         className={classnames('selector-option', { 'bold': props.selected })}>
      {props.label}
    </div>
  )
}

Option.propTypes = {
  selected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setSelectedOption: PropTypes.func.isRequired
}

function FilterOption (props) {

  const handleClick = (event) => {
    let newOptions = { ...props.selectedFilterOptions }
    newOptions[props.value] = event.target.checked
    props.setSelectedFilterOptions(newOptions)
  }

  return (
    <div className="other-filters-option">
      <span>{props.label}</span>
      <Checkbox
        checked={props.selectedFilterOptions[props.value] === true}
        onChange={handleClick}
        type="checkbox"
        color={'primary'}
      />
    </div>
  )
}

FilterOption.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setSelectedFilterOptions: PropTypes.func.isRequired,
  selectedFilterOptions: PropTypes.object.isRequired,
}
