import React, { useState } from 'react'
import './Contact.css'

export default function Contact () {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  const onSubmit = (event) => {
    event.preventDefault()
    fetch('/email/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        name,
        subject,
        text: message,
      }),
    })
      .then(res => {
        if (res.status === 200) {
          alert('Email sent!')
        } else {
          alert('Error sending email!')
        }
      })
      .catch(res => {
        alert('Sorry, we failed to send the email through this form. Try sending us one directly: info@thecampanileproject.com')
      })
    document.getElementById('contact-form')
      .reset()
    setName('')
    setEmail('')
    setSubject('')
    setMessage('')
  }

  return (
    <div className="contact">
      <div className="contact-headers">
        <div>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
          <i className="fa fa-envelope" style={{fontSize: "70px"}}></i>
        </div>
        <div>
          Contact Us! If the form does not work or you would prefer a direct email,
          use <a className="email-link" href="mailto:info@campanileproject.org"
                 target="_blank" rel="noopener noreferrer" >info@campanileproject.org</a>
        </div>
      </div>
      <div className="lower-contact">
        <div className="contact-labels">
          <div>Name</div>
          <div>Email</div>
          <div>Subject</div>
          <div>Message</div>
        </div>
        <form id="contact-form" className="contact-form" onSubmit={onSubmit}>
          <input type="text" onChange={event => setName(event.target.value)}/>
          <input type="text" onChange={event => setEmail(event.target.value)}/>
          <input type="text" onChange={event => setSubject(event.target.value)}/>
          <textarea onChange={event => setMessage(event.target.value)}>
          </textarea>
          <input className="contact-submit" type="submit"/>
        </form>
      </div>
    </div>
  )
}
