import React from 'react'
import { Link } from 'react-router-dom'

import './TopScrollLink.css'

/**
 * Wrapper for React Router Link which on clicking the link scrolls to the top of the linked page.
 * @param props
 * @returns {*}
 * @constructor
 */
export default function TopScrollLink (props) {
  return (
    <Link {...props} className={'top-scroll-link ' + props.className}
          onClick={() => {
              if (props.onClick) {
                  props.onClick()
              }
              window.scrollTo(0, 0)
          }}>
      {props.children}
    </Link>
  )
}
