import React from 'react'

import data from './HomePageData'
import { RandomScrollContainer } from '../../components/cards/HoverCardContainer'

import './HomePage.css'
import TopScrollLink from '../../components/topscrolllink/TopScrollLink'

export default function HomePage (props) {

  return (
    <div className="flex-column align-center homepage-container">
      <div className="top-shape"/>
      <div className="homepage-tag-line-div">
        <img src={'/images/logos/CAMPANILE_LOGO_TEXT.svg'}
             className="homepage-logo" alt={''}/>
        <video className="homepage-video" controls width="250">
          <source src="https://campanileproject.s3.us-east-2.amazonaws.com/homepage-video.mp4"
                  type={'video/mp4'}/>
        </video>
      </div>
      <div className="homepage-title-div">
        <div><strong>{data.titleLine}</strong></div>
        <div className="homepage-subtitle">
          <div>
            {data.subtitle}
            <strong> The Campanile Project is designed to upheave malpractices in this industry and
              ensure that volunteers are able to make a responsible, lasting contribution</strong>
          </div>
        </div>
      </div>
      <div className="homepage-activity-div">
        <TopScrollLink className="homepage-activity-image-container" to="/activity">
          <img className="homepage-activity-image" alt="" src={data.activity.image}/>
          <p className="homepage-activity-img-text">{data.activity.imageText}</p>
        </TopScrollLink>
        <div style={{
          marginRight: '50px',
          marginLeft: '50px'
        }} className="homepage-activity-text-container ">
          <p className="homepage-activity-title">
            {data.activity.title}
          </p>
          <div className="homepage-activity-text"><strong>We all work best on issues that we are
            most passionate about.</strong> {data.activity.description}</div>
        </div>
      </div>
      <div className="homepage-location-div">
        <div style={{ marginLeft: '50px' }} className="homepage-location-text-container">
          <p className="homepage-location-title">
            {data.location.title}
          </p>
          <div className="homepage-location-text">Through learning foreign culture, interacting with
            locals, and experiencing the new sights, sounds, and smells of your surroundings,
            volunteering allows you to be a true global citizen. Volunteering is much more than just
            being a tourist. <strong>By making a genuine impact in communities abroad, you’re able
              to flourish as a member in a global community.</strong> Our partnered programs provide
            diverse experiences across 5 continents and dozens of countries.
          </div>
        </div>
        <TopScrollLink className="homepage-location-image-container" to="/region">
          <img className="homepage-location-image" alt="" src={data.location.image}/>
          <p className="homepage-location-img-text">{data.location.imageText}</p>
        </TopScrollLink>
      </div>
      <div className="homepage-differentiation-div">
        <div className="homepage-differentiation-text-container">
          <p className="homepage-location-title">
            OUR INTEGRITY PROMISE
          </p>
          <div className="homepage-differentiation-text">All projects provided here are vetted
            exclusively to guarantee meaningful volunteer placements. We have no ulterior incentive
            to generate our own income or increase the amount of volunteer placements. <strong>Thus,
              we provide complete details of our partner organizations—the same organizations used
              across online agencies—at a fraction of the cost and increased tenfold in
              accessibility.</strong> At the Campanile Project, there is nothing to hide.
          </div>
        </div>
        <TopScrollLink className="homepage-differentiation-image-container" to="/about">
          <img className="homepage-differentiation-image" alt="" src={data.differentiation.image}/>
          <p className="homepage-differentiation-img-text">{data.differentiation.imageText}</p>
        </TopScrollLink>
      </div>
      <div className="lower-differentiation-text">
        <p>
          We are students from the University of California, Berkeley, committed to providing
          ethical opportunities for volunteers. Driven by our own experiences as volunteers,
          students, and members of our larger global community, <strong>we’re convinced that full
          transparency is needed for truly worthwhile volunteering placements.</strong> As such, we’re the
          sole provider of vetted volunteering organizations with full disclosure and zero
          administration fees—for both the volunteer and our organizations. We seek to transform how
          volunteering abroad should be done.
        </p>
      </div>

      <div style={{ marginTop: '40px' }} className="flex-center">
        <RandomScrollContainer/>
      </div>
    </div>

  )
}
