import React, {useState} from "react"
import "./GenericSlider.css"
import Slider from '@material-ui/core/Slider';
import PropTypes from "prop-types"

/**
 * Generic Slider. Pass it a value and a function to change that value using a setState function as props
 * for it to store and change values properly. Multiple values in an array like [2, 5] will create multiple
 * handles on the slider
 * @param props
 * @returns {*}
 * @constructor
 */
export default function GenericSlider(props) {
    const [val, setVal] = useState(props.value)

    return (
        <Slider
            value={val}
            onChange={(event, newValue) => setVal(newValue)}
            onChangeCommitted={props.onChange}
            marks={true}
            min={props.min}
            max={props.max}
            aria-labelledby="range-slider"
            track={props.disableTrack ? false : 'normal'}
            valueLabelDisplay={props.valueLabelDisplay ? props.valueLabelDisplay : 'auto'}
        />
    )
}

GenericSlider.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired,
    onChange: PropTypes.func.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    disableTrack: PropTypes.bool,
    valueLabelDisplay: PropTypes.string,
}
