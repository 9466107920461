import React from "react";

import TopScrollLink from "../../components/topscrolllink/TopScrollLink";
import data from "./ActivityPageData";
import "./InfoPage.css";
import "./ActivityPage.css";
import Testimonial from "../../components/testimonials/Testimonial";
import { RandomScrollContainer } from "../../components/cards/HoverCardContainer";

export default function ActivityPage() {
  return (
    <div className="info-page">
      <div className="info-page-top-section">
        <p className="info-page-title">{data.title}</p>
        <p className="info-page-text">{data["top-description"]}</p>
        <p className="info-page-tagline">{data["top-tagline"]}</p>
      </div>
      <div className="info-page-rotating-block">
        <div className="see-all-locations-block">
          <p className="see-all-locations">SORT BY ACTIVITIES</p>
          <div className="info-page-text">{data["what-will-you-do-paragraph"]}</div>
        </div>
        <TopScrollLink to="/search/activity/conservation" className="info-page-img-container">
          <img src={data.conservation_image} alt=""/>
          <div style={{
            bottom: "10px",
            left: "10px",
            fontSize: "42px"
          }}>CONSERVATION
          </div>
        </TopScrollLink>
      </div>
      <div className="info-page-rotating-block">
        <TopScrollLink to="/search/activity/education" className="info-page-img-container">
          <img src={data.education_image} alt=""/>
          <div style={{
            right: "20px",
            bottom: "10px"
          }}>
            <div>CHILDREN +</div>
            <div>EDUCATION</div>
          </div>
        </TopScrollLink>
        <TopScrollLink to="/search/activity/community" className="info-page-img-container">
          <img src={data.community_image} alt=""/>
          <div style={{
            top: "10px",
            right: "25px"
          }}>COMMUNITY
          </div>
        </TopScrollLink>
      </div>
      <div className="info-page-rotating-block" style={{ height: "auto" }}>
        <div className="activity-page-bottom-link-container">
          <TopScrollLink to="/search/activity/medical"
                         className="info-page-img-container medical-box">
            <img src={data.medical_image} alt=""/>
            <div style={{
              top: "10px",
              right: "25px"
            }}>MEDICAL
            </div>
          </TopScrollLink>
          <TopScrollLink style={{ maxHeight: "170px" }} to="/search/all"
                         className="info-page-img-container see-all-programs-div">
            <img src={data.all_programs_img} alt=""/>
            <div style={{
              bottom: "5px",
              right: "25px",
              fontSize: "35px",
              lineHeight: "42px"
            }}>SEE ALL PROGRAMS
            </div>
          </TopScrollLink>
          {/*<div className="voluntourism-quote">*/}
          {/*  HELPING HANDS CREATE MEANINGFUL CONNECTIONS*/}
          {/*</div>*/}
        </div>
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <div>
            <div className="flex-column more-info-text">
              <p className="info-page-more-info">{data["more-info-title"]}</p>
              <div className="more-info-block info-page-text">{data["more-info"]}</div>
              <p className="more-info-block info-page-text">{data["more-info-2"]}</p>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
      <Testimonial name={data.testimonial1.name}
                   text={data.testimonial1.text}
                   image={data.testimonial1.image}
                   align="left"
      />
      <Testimonial name={data.testimonial2.name}
                   text={data.testimonial2.text}
                   image={data.testimonial2.image}
                   align="right"
      />
      <div style={{ marginTop: "40px" }} className="flex-center">
        <RandomScrollContainer/>
      </div>
    </div>
  );
}
