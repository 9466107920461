import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './Testimonial.css'

export default function Testimonial (props) {
  let align = props.align
  if (!align) {
    align = 'left'
  }
  return (
    <div className="testimonial-container">
      {align === 'left' &&
      image(props.image)
      }
      <div className="testimonial-text-container">
        <div className='testimonial-text'>{props.text}</div>
        <div className={classnames('testimonial-name', { 'align-left': align === 'left' })}>
          -{props.name}
        </div>
      </div>
      {align === 'right' &&
      image(props.image)
      }
    </div>
  )
}

function image (src) {
  return (
    <div className="testimonial-image-container">
      <div className="testimonial-image" style={{ backgroundImage: `url("${src}")` }}>
        {/*<img src={src} alt="Quote provider head shot"/>*/}
      </div>
    </div>
  )
}

Testimonial.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  align: PropTypes.string,
}
