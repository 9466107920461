import React, {Children, cloneElement, isValidElement, useState} from "react";
import classnames from "classnames"

import Spinner from "./Spinner";

/**
 * Higher Order Component which hides a component (or multiple components) which are loading content and replaces
 * them with a spinner animation.
 * Usage: Wrap the tag that will be loading in this tag. Then, when coding the component make sure when it has loaded
 * you call `props.setLoaded()` —> This callback will reach back to this component and tell it to show the inner
 * props.children
 *
 * <WithLoader>
 *    <LoadingComponent/>
 * </WithLoader>
 */
export default function WithLoading(props) {
    const [loaded, setLoaded] = useState()

    // Copies all of props.children except with setLoaded added to them.
    const childrenWithProps = Children.map(props.children, (child) => {
        // Checking isValidElement is the safe way and avoids a TS error too.
        if (isValidElement(child)) {
            return cloneElement(child, {setLoaded})
        }
    })

    return (
        <div>
            {/*Loads hidden so that the prop can render and perform any necessary actions to instantiate a load.*/}
            <div className={classnames({"hidden": !loaded})}>{childrenWithProps}</div>
            {!loaded && <Spinner/>}
        </div>
    )
}
