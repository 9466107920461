import React, {useState, useEffect, useRef} from "react"
import classnames from "classnames"

import TopScrollLink from '../topscrolllink/TopScrollLink'
import "./BurgerMenu.css"

export default function BurgerMenu({items}) {
    const [open, setOpen] = useState(false)
    const node = useRef();
    useOnClickOutside(node, () => setOpen(false));

    return (
        <Menu items={items} open={open} setOpen={setOpen}>
            <Burger open={open} setOpen={setOpen}/>
        </Menu>
    )
}

function Burger({open, setOpen}) {
    return (
        <div className={classnames("burger-container", {"burger-change": open})} onClick={setOpen.bind(this, !open)}>
            <div className="burger-bar1"></div>
            <div className="burger-bar2"></div>
            <div className="burger-bar3"></div>
        </div>
    )
}

const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
            const listener = event => {
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener('mousedown', listener);
            return () => {
                document.removeEventListener('mousedown', listener);
            };
        },
        [ref, handler],
    );
};

const Menu = ({items, children, open, setOpen}) => {
    return (
        <div className={classnames("menu-container", {"menu-background": open})}>
            {children}
            {open && items.map((item) =>
                <TopScrollLink to={item.url} className='link menu-link' key={item.name} onClick={setOpen.bind(this, false)}>
                    {item.name}
                </TopScrollLink>
            )}
        </div>
    )
}
