import React from "react";

import TopScrollLink from "../../components/topscrolllink/TopScrollLink";
import data from "./RegionPageData";
import "./InfoPage.css";
import Testimonial from "../../components/testimonials/Testimonial";
import { RandomScrollContainer } from "../../components/cards/HoverCardContainer";

export default function RegionPage() {
  return (
    <div className="info-page">
      <div className="info-page-top-section">
        <p className="info-page-title">{data.title}</p>
        <p className="info-page-text">{data["top-description"]}</p>
        <p className="info-page-tagline">{data["top-tagline"]}</p>
      </div>
      <div className="info-page-rotating-block">
        <div className="see-all-locations-block">
          <p className="see-all-locations">SORT BY REGION</p>
          <div className="info-page-text">{data["see-all-paragraph"]}</div>
        </div>
        <TopScrollLink to="/search/region/africa" className="info-page-img-container">
          <img src={data.africa_image} alt=""/>
          <div style={{
            bottom: "10px",
            left: "25px"
          }}>AFRICA
          </div>
        </TopScrollLink>
      </div>
      <div className="info-page-rotating-block">
        <TopScrollLink to="/search/region/central_america" className="info-page-img-container">
          <img src={data.north_america_image} alt=""/>
          <div style={{
            right: "20px",
            bottom: "10px"
          }}>
            <div>CENTRAL</div>
            <div>+ NORTH</div>
            <div>AMERICA</div>
          </div>
        </TopScrollLink>
        <TopScrollLink to="/search/region/asia" className="info-page-img-container">
          <img src={data.asia_image} alt=""/>
          <div style={{
            top: "10px",
            right: "25px"
          }}>ASIA
          </div>
        </TopScrollLink>
      </div>
      <div className="info-page-rotating-block" style={{ height: "auto" }}>
        <div className="info-page-bottom-link-container">
          <TopScrollLink to="/search/region/south_america" className="info-page-img-container">
            <img src={data.south_america_image} alt=""/>
            <div style={{
              top: "10px",
              left: "15px"
            }}>SOUTH AMERICA
            </div>
          </TopScrollLink>
          <TopScrollLink to="/search/region/europe" className="info-page-img-container">
            <img src={data.europe_image} alt=""/>
            <div style={{
              top: "10px",
              right: "25px"
            }}>EUROPE
            </div>
          </TopScrollLink>
        </div>
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <div className="flex-column">
            <p className="info-page-more-info">{data["more-info-title"]}</p>
            <div className="more-info-block info-page-text">{data["more-info"]}</div>
            <p className="more-info-block info-page-text">{data["more-info-2"]}</p>
          </div>
        </div>
      </div>
      <div className="info-page-short-link-block">
        <div className="voluntourism-quote">
          {data.anti_voluntourism}
        </div>
        <TopScrollLink to="/search/all" className="info-page-img-container see-all-img">
          <img src={data.all_programs_img} alt=""/>
          <div style={{
            bottom: "5px",
            right: "25px",
            fontSize: "45px"
          }}>SEE ALL PROGRAMS
          </div>
        </TopScrollLink>
      </div>
      <Testimonial name={data.testimonial1.name}
                   text={data.testimonial1.text}
                   image={data.testimonial1.image}
                   align="left"
      />
      <Testimonial name={data.testimonial2.name}
                   text={data.testimonial2.text}
                   image={data.testimonial2.image}
                   align="right"
      />
      <div style={{ marginTop: "40px" }} className="flex-center">
        <RandomScrollContainer/>
      </div>
    </div>
  );
}
