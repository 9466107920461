import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import useDeepCompareEffect from 'use-deep-compare-effect'

import HoverCard from './HoverCard'
import WithLoading from '../loading/WithLoader'

import './HoverCardContainer.css'

function HoverCardContainer (props) {

  return (
    <div style={{
      width: '100%',
      height: 'auto'
    }}>
      <WithLoading>
        <CardHolder {...props}/>
      </WithLoading>
    </div>
  )
}

const CARD_WIDTH = 260

CardHolder.propTypes = {
  numToDisplay: PropTypes.number,
  searchBody: PropTypes.object.isRequired,
  limitDisplay: PropTypes.bool,
  sideScroll: PropTypes.bool,
  min: PropTypes.number,
  rows: PropTypes.number,
}

function CardHolder (props) {
  const [width, setWidth] = useState(0)
  const widthRef = useRef(null)
  const [cards, setCards] = useState([])

  const setWidthOnContainer = () => setWidth(widthRef.current.offsetWidth)

  const calcNumToDisplay = () => {
    if (props.sideScroll) {
      return cards.length
    }
    let val = Math.max(Math.floor(width / CARD_WIDTH * (props.rows || 2)), (props.min || 2))
    if (val % 2 !== 0) {
      val -= 1
    }
    return val
  }

  const [showMore, setShowMore] = useState(false)

  const numToDisplayFirst = props.numToDisplay || calcNumToDisplay()

  let useEffectVar = widthRef.current && widthRef.current.offsetWidth

  useEffect(() => {
    setWidth(widthRef.current.offsetWidth)
    window.addEventListener('resize', setWidthOnContainer)
    return () => {
      window.removeEventListener('resize', setWidthOnContainer)
    }
  }, [useEffectVar])

  useDeepCompareEffect(
    () => {
      props.setLoaded(false)
      fetch('/db/searchorgs', {
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify(props.searchBody),
        method: 'POST',
      })
        .then(
          res => {
            res.json()
              .then(
                cardsList => {
                  const newCards = []
                  for (let i = 0; i < cardsList.length; i++) {
                    const json = cardsList[i]
                    newCards.push(<HoverCard
                      key={json.id + `-${i}`}
                      imageURL={json.profile_pic}
                      org_name={json.org_name}
                      country={json.country}
                      city={json.city}
                      program_name={json.program_name}
                      id={json.id}
                      size={{
                        width: '200px',
                        height: '300px'
                      }}
                    />)
                  }
                  props.setLoaded(true)
                  setCards(newCards)
                }
              )
          }
        )
    },
    [props.searchBody],
  )

  if (cards.length > 0) {
    return (
      <div ref={widthRef} className="flex-column align-center">
        <div
          className={classnames(props.sideScroll ? 'hover-card-container-straight' : 'hover-card-container', {
            'row-based-container': !props.sideScroll,
            'side-scroll-container': props.sideScroll,
          })} id="hover-card-container"
        >
          {showMore ?
            cards
            :
            cards.slice(0, Math.min(cards.length, numToDisplayFirst))
          }
        </div>
        {!props.limitDisplay && !showMore && cards.length > numToDisplayFirst &&
        <button className="show-more-button"
                onClick={setShowMore.bind(this, true)}
        >SHOW MORE PROGRAMS</button>
        }
      </div>
    )
  } else {
    return (
      <div ref={widthRef} className="flex-column"
           style={{
             justifyContent: 'center',
             alignItems: 'center',
             height: '700px'
           }}>
        <p>No organizations found for those filters.</p>
      </div>
    )
  }
}

export function RandomScrollContainer ({ limit }) {
  let cardLimit = limit
  if (!cardLimit) {
    cardLimit = 6
  }
  return <HoverCardContainer searchBody={{
    limit: cardLimit,
    random: true
  }} limitDisplay={true} rows={1} min={1} sideScroll={true}/>

}

export default React.memo(HoverCardContainer)
