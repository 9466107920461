import React from 'react'
import PropTypes from 'prop-types'
import GenericSlider from '../slider/GenericSlider'
import './FilterBar.css'

export default function FilterBar (props) {

  return (
    <div className="filter-bar">
      <FilterBarRow
        title={'HOW OLD ARE YOU?'}
        leftLabel={'16'}
        rightLabel={'24+'}
      ><AgeSlider value={props.age} setValue={props.setAge}/></FilterBarRow>
      <FilterBarRow
        title={'HOW LONG DO YOU PLAN ON VOLUNTEERING?'}
        leftLabel={'2 weeks or less'}
        rightLabel={'8 weeks+'}
      ><DurationSlider value={props.duration} setValue={props.setDuration}/></FilterBarRow>
      <div className="filter-bar-bottom-text">In the interest of program beneficiaries, most
        volunteering programs have requirements on how old you should be at the time of volunteering
        and how long you’re able to volunteer for. As such, we will automatically filter out
        programs of which you do not meet the requirements. If you’re under the required age and
        would still like to participate in a certain program, make sure to set the age to 18 and
        look for programs that support age exceptions.
      </div>
    </div>
  )
}

FilterBar.propTypes = {
  age: PropTypes.number.isRequired,
  setAge: PropTypes.func.isRequired,
  duration: PropTypes.arrayOf(PropTypes.number).isRequired,
  setDuration: PropTypes.func.isRequired,
}

function FilterBarRow (props) {
  return (
    <div className="filter-bar-row">
      <div className="filter-bar-title">{props.title}</div>
      <div className="filter-bar-right">
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
          <div className="filter-bar-left-label">{props.leftLabel}</div>
        </div>
        <div className="filter-bar-slider">{props.children}</div>
        <div className="filter-bar-right-label">{props.rightLabel}</div>
      </div>
    </div>
  )
}

function AgeSlider (props) {
  return (
    <GenericSlider
      value={props.value}
      onChange={(event, newValue) => props.setValue(newValue)}
      min={16}
      max={24}
      disableTrack={true}
    />
  )
}

function DurationSlider (props) {
  return (
    <GenericSlider
      value={props.value}
      onChange={(event, newValue) => props.setValue(newValue)}
      min={2}
      max={8}
    />
  )
}
