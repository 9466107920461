import React from 'react'
import { useParams } from 'react-router-dom'

import './ExtendedHowTo.css'
import data from './HowToData'
import NotFound from '../NotFound'

export default function ExtendedHowTo (props) {

  let { topic } = useParams()
  let pageData = null
  console.log(data.lines)

  for (let i = 0; i < data.lines.length; i++) {
    console.log(data.lines[i])
    if (topic === data.lines[i].key) {
      pageData = data.lines[i]
      break
    }
  }

  if (pageData) {
    return (
      <div className="extendedhowto-container">
        <div className="extended-howto-header">
          {pageData.title.toUpperCase()}
        </div>
        <div className="extended-howto-text">
          {pageData.extended_background}
        </div>
        <br />
        <div className="extended-howto-text">
          {pageData.extended_background_2}
        </div>
        <div className="extended-howto-section-one">
          <img src={pageData.first_image} alt=""/>
          <p className="extended-howto-text">
            {pageData.second_background}
          </p>
        </div>
        <div className="extended-howto-section-two">
          <p className="extended-howto-text">
            {pageData.third_background}
          </p>
          <img src={pageData.second_image} alt=""/>
        </div>
      </div>
    )
  } else {
    return <NotFound/>
  }
}
