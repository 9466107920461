import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import Media from 'react-media'
import { useParams } from 'react-router-dom'
import { SocialIcon } from 'react-social-icons'
import ImageGallery from 'react-image-gallery'

import './OrgPage.css'
import TopScrollLink from '../../components/topscrolllink/TopScrollLink'
import WithLoading from '../../components/loading/WithLoader'
import Testimonial from '../../components/testimonials/Testimonial'

export default function OrgPage (props) {
  if (props.orgData) {
    return <OrgPageToLoad {...props} />
  } else {
    return (
      <WithLoading>
        <OrgPageToLoad {...props} />
      </WithLoading>
    )
  }
}

/**
 * A page for an organization. Will be refactored to generate itself based off of a URL so it can be routed to.
 * Should either receive a full set of orgData and costData through props, or the id of the organization through react router
 * Relative URL should be: /org/:id
 * @returns {*}
 * @constructor
 */
function OrgPageToLoad (props) {

  let { id } = useParams()

  const [state, setState] = useState({
    orgData: props.orgData,
    costData: props.costData,
  })

  let orgData = props.orgData
  useEffect(() => {
    if (!orgData) {
      fetch(`/db/org-by-id/${id}`, {
        method: 'GET',
      })
        .then(
          response => {
            response.json()
              .then(
                json => {
                  const costs = json.costs
                  const org = (Array.isArray(json.org)) ? json.org[0] : json.org
                  props.setLoaded(true)
                  const newState = {
                    orgData: org,
                    costData: costs
                  }
                  setState(newState)
                }
              )
          }
        )
    }
  }, [id, orgData, props])

  const page = () => {

    const orgData = state.orgData
    const costData = state.costData

    const determineAdditionalInfo = (box_key) => {
      const additionalInfo = orgData.additional_information
      if (additionalInfo) {
        return (
          <div className="org-image-container org-page-additional-info">
            <div className="additional-org-info-title">
              Additional information
            </div>
            <div>
              {additionalInfo}
            </div>
          </div>
        )
      } else {
        return (
          <div className="org-image-container">
            <img src={orgData[box_key]}
                 alt="Organization-Related Decoration"
            />
          </div>
        )
      }
    }

    const whoWeAreContainer = () => {
      return (
        <div className="who-we-are-section">
          <div>
            <div className="paragraph-caption">
              Who we are
            </div>
            <div className="paragraph-text">
              {orgData.who_we_are}
            </div>
          </div>
          <div>
            <div className="paragraph-caption">
              What you do
            </div>
            <div className="paragraph-text">
              {orgData.what_you_do}
            </div>
          </div>
        </div>
      )
    }

    const imageGalleryBottom = () => {
      const additionalInfo = orgData.additional_information
      let images = [
        {
          original: orgData.box_2,
          originalClass: 'org-carousel-image'
        },
        {
          original: orgData.box_4,
          originalClass: 'org-carousel-image'
        },
        {
          original: orgData.box_5,
          originalClass: 'org-carousel-image'
        },
        {
          original: orgData.box_6,
          originalClass: 'org-carousel-image'
        },
      ]
      if (!additionalInfo) {
        images.unshift({
          original: orgData.box_3,
          originalClass: 'org-carousel-image'
        })
      }

      return (
        <React.Fragment>
          <div style={{ marginTop: '25px' }}/>
          <ImageGallery
            items={images}
            showThumbnails={false}
            showPlayButton={false}
            showFullscreenButton={false}
          />
          {additionalInfo &&
          <div style={{
            width: '90%',
            marginTop: '25px'
          }} className="org-image-container org-page-additional-info">
            <div className="additional-org-info-title">
              Additional information
            </div>
            <div className="paragraph-text">
              {additionalInfo}
            </div>
          </div>}
        </React.Fragment>
      )
    }

    return (
      <div className="org-page-container">
        {
          (orgData && costData) &&
          <Media queries={{
            small: '(max-width: 699px)',
            medium_desktop: '(max-width: 1199px)',
            large_desktop: '(min-width: 1200px',
          }}>
            {matches => {
              return (
                <React.Fragment>
                  <div className="org-page-upper-section">
                    <InfoSection orgData={orgData} costData={costData}/>
                    <div className="org-page-upper-right">
                      <div className="top-images">
                        <div className="top-image-container">
                          <img src={orgData.box_1}
                               alt="Organization-Related Decoration"
                          />
                        </div>
                        {!matches.small &&
                        <div className="top-image-container">
                          <img src={orgData.box_2}
                               alt="Organization-Related Decoration"
                          />
                        </div>
                        }
                      </div>
                      {!matches.medium_desktop &&
                      whoWeAreContainer()
                      }
                    </div>
                  </div>
                  {matches.medium_desktop && whoWeAreContainer()}
                  <div className="why-your-work-matters-section">
                    <div
                      className="paragraph-caption bottom-paragraph-caption">
                      Why your work matters
                    </div>
                    <div className="paragraph-text">
                      {orgData.why_you_matter}
                    </div>
                  </div>
                  {orgData.quote_person && orgData.quote &&
                  <div className="org-page-testimonial">
                    <Testimonial name={orgData.quote_person}
                                 text={orgData.quote}
                                 image={orgData.quote_image}/>
                  </div>
                  }
                  {!matches.small ?
                    <React.Fragment>
                      <div className="org-image-section">
                        {determineAdditionalInfo('box_3')}
                        <div className="org-image-container">
                          <img src={orgData.box_4}
                               alt="Organization-Related Decoration"
                          />
                        </div>
                      </div>
                      < div className="org-image-section">
                        <div className="org-image-container">
                          <img src={orgData.box_5}
                               alt="Organization-Related Decoration"
                          />
                        </div>
                        <div className="org-image-container">
                          <img src={orgData.box_6}
                               alt="Organization-Related Decoration"
                          />
                        </div>
                      </div>
                    </React.Fragment>
                    :
                    imageGalleryBottom()
                  }
                </React.Fragment>)
            }}
          </Media>
        }
      </div>
    )
  }
  return page()
}

function InfoSection (props) {

  const orgData = props.orgData

  const parseAge = () => {
    let ageText = ''

    /* eslint-disable-next-line eqeqeq */
    if (orgData.min_age != 0 && orgData.max_age != 200) {
      ageText = `${orgData.min_age}-${orgData.max_age}`
      /* eslint-disable-next-line eqeqeq */
    } else if (orgData.max_age != 200) {
      ageText = `${orgData.max_age} and under`
      /* eslint-disable-next-line eqeqeq */
    } else if (orgData.min_age != 0 && orgData.max_age == 200) {
      ageText = `${orgData.min_age}+`
    } else {
      ageText = 'No specified age restriction'
    }

    if (orgData.age_exceptions) {
      ageText += ' (Age Exceptions Permitted).'
    }
    return ageText
  }

  const parseUnit = (duration, unit) => {
    if (duration > 1) {
      return `${unit}s`
    }
    return unit
  }

  const parseDuration = () => {
    if (orgData.min_duration >= 1 && orgData.max_duration < 200 && orgData.max_duration > orgData.min_duration) {
      return `${orgData.min_duration} ${parseUnit(orgData.min_duration, 'week')} - ${orgData.max_duration} ${parseUnit(orgData.max_duration, 'week')}`
    } else if (orgData.min_duration >= 1) {
      return `Minimum ${orgData.min_duration} ${parseUnit(orgData.min_duration, 'week')}`
    } else if (orgData.max_duration < 200) {
      return `Maximum ${orgData.max_duration} ${parseUnit(orgData.max_duration, 'week')}`
    } else {
      return 'No specified time duration'
    }
  }

  const parseSpecialSkills = () => {
    let result = []
    if (orgData.special_skills) {
      result.push(orgData.special_skills)
    }
    if (result.length === 0) {
      return 'No special qualifications needed.'
    } else {
      return result.join(', ')
    }
  }

  const parseSocial = () => {
    let socials = []
    if (orgData.email) {
      socials.push(<span className="socials-component" key={'email'}><SocialIcon
        url={`mailto:${orgData.email}`}
        network="email"/></span>)
    }
    if (orgData.facebook_link) {
      socials.push(<span className="socials-component" key={'facebook'}><SocialIcon
        url={orgData.facebook_link}
        network="facebook"/></span>)
    }
    if (orgData.twitter_link) {
      socials.push(<span className="socials-component" key={'twitter'}><SocialIcon
        url={orgData.twitter_link}
        network="twitter"/></span>)
    }
    if (orgData.youtube_link) {
      socials.push(<span className="socials-component" key={'youtube'}><SocialIcon
        url={orgData.youtube_link}
        network="youtube"/></span>)
    }
    if (socials.length > 0) {
      return (
        <div className="socials-div">
          {socials}
        </div>
      )
    }
  }

  return (
    <div className="info-section">
      <h2 className="info-section-header">{orgData.program_name}</h2>
      <h3 className="info-section-subheader">{orgData.org_name}</h3>
      {orgData.city ?
        orgData.country ?
          <h3 className="info-section-subheader">{`${orgData.city}, ${orgData.country}`}</h3>
          :
          <h3 className="info-section-subheader">{orgData.city}</h3>
        :
        orgData.country ?
          <h3 className="info-section-subheader">{orgData.country}</h3>
          :
          null
      }
      <div className="info-block">
        <InfoSectionLine
          label="Age:"
          data={parseAge()}
        />
        <InfoSectionLine
          label="Duration of Time:"
          data={parseDuration()}
        />
        <InfoSectionLine
          label="Special Requirements:"
          data={parseSpecialSkills()}
        />
        <CostData costData={props.costData} orgData={props.orgData}/>
        {orgData.website_link &&
        <a target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}
           href={orgData.website_link}>
          <div className="more-info">Go to program page</div>
        </a>}
        {orgData.has_handbook &&
        <TopScrollLink to={`/org/${orgData.id}/pdf`} params={{ id: orgData.id }}
              style={{ textDecoration: 'none' }}>
          <div className="more-info">See all information</div>
        </TopScrollLink>
        }
        <div className="social-icons-container">{parseSocial()}</div>
      </div>
    </div>
  )
}

InfoSection.propTypes = {
  orgData: PropTypes.object.isRequired,
  costData: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    duration_unit: PropTypes.string,
    per_unit: PropTypes.bool.isRequired,
    one_time: PropTypes.bool.isRequired,
  }))
}

function InfoSectionLine (props) {
  return (
    <div className="info-line">
      <div><strong>{props.label}</strong></div>
      <div>{props.data}</div>
    </div>
  )
}

InfoSectionLine.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
}

function CostData (props) {

  const items = props.costData

  // Parse the workable option tag values from the API data
  let options = []
  const values = {}
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    let value = `$${item.cost} USD`
    let label
    let unit = item.duration_unit
    if (item.duration > 1) {
      unit += 's'
    }
    // eslint-disable-next-line
    if (item.cost == 0) {
      value = 'Free!'
    }

    if (item.per_unit) {
      // eslint-disable-next-line
      label = item.cost == 0 ? '' : `Per ${unit}`
    } else if (item.one_time) {
      // eslint-disable-next-line
      label =  item.cost == 0 ? '' : `One Time Payment`
    } else {
      label = `${item.duration} ${unit}`
    }

    let id = value + label + i

    values[id] = {
      value: value,
      label: label
    }
    options.push(
      {
        value: id,
        label: label
      }
    )
  }

  const parseExtraCostData = () => {
    let vals = []
    const entries = Object.entries(props.orgData)
    for (let i = 0; i < entries.length; i++) {
      const val = entries[i][0]
      if (entries[i][1]) {
        // eslint-disable-next-line
        switch (val) {
          case 'on_site_accomodation':
            vals.push('on site accomodation')
            break
          case 'three_meals':
            vals.push('3 meals per day')
            break
          case 'one_meal':
            vals.push('1 meal per day')
            break
          case 'airport_transport':
            vals.push('airport transfer')
            break
        }
      }
    }
    if (vals.length > 0) {
      return 'Including: ' + vals.join(', ')
    }
  }

  const [value, setValue] = useState(options[0])

  const handleChange = (selectedOption) => {
    setValue(selectedOption)
  }

  return (
    <div className="cost-block">
            <span className="cost-select">
                <span
                  className="cost-text"><strong>Cost{options.length > 1 ? ' For' : null}: </strong></span>
              {options.length > 1 ?
                <Select
                  className="cost-dropdown"
                  value={value}
                  onChange={handleChange}
                  options={options}
                />
                :
                // The values object is indexed by independent id's for purpose of select items which might have the same cost
                // for different times
                <div
                  className="org-cost-block">{value ? `${values[value.value].value} ${values[value.value].label}` : 'No cost data available.'}</div>
              }
            </span>
      {options.length > 1 && <div style={{ alignSelf: 'center' }}><strong>{value ? values[value.value].value : 'No cost data available.'}</strong></div>}
      <div className="cost-lower-text">{parseExtraCostData()}</div>
    </div>
  )
}

CostData.propTypes = {
  costData: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    duration_unit: PropTypes.string
  })).isRequired,
  orgData: PropTypes.object.isRequired
}

