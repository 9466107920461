import React, { useState, useEffect } from 'react'
import Media from 'react-media'
import PropTypes from 'prop-types';

import './NavBar.css'
import BurgerMenu from '../hamburgermenu/BurgerMenu'
import TopScrollLink from '../topscrolllink/TopScrollLink'
import { withRouter } from 'react-router-dom'

function Navbar (props) {

  const [showLogo, setShowLogo] = useState(window.location.pathname !== '/')

  useEffect(() => {
    return props.history.listen(({ pathname }) => {
      setShowLogo(pathname !== '/')
    })
  }, [props.history])

  let pages = [
    {
      url: '/region',
      name: 'REGION'
    },
    {
      url: '/activity',
      name: 'ACTIVITY'
    },
    {
      url: '/how-to',
      name: 'HOW-TO'
    },
    {
      url: '/search',
      name: 'SEE ALL OPPORTUNITIES'
    },
    {
      url: '/about',
      name: 'WHY WE\'RE DIFFERENT'
    }]

  return (
    <Media queries={{
      small: '(max-width: 984px)',
    }}>
      {matches => (
        <div className='navbar'>
          {showLogo &&
          <TopScrollLink to={'/'} onClick={() => setShowLogo(false)}>
            <img src={'/images/logos/CAMPANILE_LOGO_TEXT.svg'} style={{
              'height': '105px',
              'marginRight': '20px'
            }} alt={''}/>
          </TopScrollLink>}
          {matches.small ?
            <BurgerMenu items={pages}/>
            :
            pages.map((items) =>
              <TopScrollLink to={items.url} className='link' key={items.name}
                             onClick={() => {setShowLogo(true)}}>
                {items.name}
              </TopScrollLink>
            )
          }
        </div>
      )}
    </Media>
  )
}

Navbar.propTypes = {
  history: PropTypes.object.isRequired,
}

export default withRouter(Navbar)
