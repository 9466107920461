import React from 'react'

import './AboutPage.css'
import data from './AboutPage.json'
// import Testimonial from '../../components/testimonials/Testimonial'
import Contact from '../../components/Contact/Contact'

export default function AboutPage () {

  const getNingSchoolYear = () => {
    let d = new Date()

    const month = d.getMonth()
    const year = d.getFullYear()

    let schoolYear

    if (year === 2020) {
      schoolYear = 'second-year'
    } else if (year === 2021) {
      if (month < 5) {
        schoolYear = 'second-year'
      } else {
        schoolYear = 'third-year'
      }
    } else if (year === 2022) {
      if (month < 5) {
        schoolYear = 'third-year'
      } else {
        schoolYear = 'fourth-year'
      }
    } else if (year === 2023) {
      if (month < 5) {
        schoolYear = 'fourth-year'
      } else {
        return 'an alum of'
      }
    } else {
      return 'an alum of'
    }

    return `currently a ${schoolYear}-year student at`
  }

  return (
    <div className="about-page">
      <div className="about-title">{data.title}</div>
      <div className="about-top-section">
        <img className="about-top-image" src={data.top_image} alt=""/>
        <p className="about-body-text">
          {data.top_text}
        </p>
      </div>
      <div className="about-body-text about-mid-text">
        <strong>The Campanile Project was born as a response to the pre-existing inefficiencies in
          the volunteering abroad industry. </strong>{data.second_text}
      </div>
      <div className="about-body-text about-mid-text">
        {data.third_text}
      </div>
      <div className="our-difference-section">
        <img className="about-us-image" src={data.difference_image} alt=""/>
        <div>
          <div className="about-subtitle">
            OUR DIFFERENCE
          </div>
          <p className="about-body-text">
            {data.difference_text}
          </p>
        </div>
      </div>
      <div className="about-body-text about-mid-text">
        This is detrimental to the integrity of volunteering abroad. <strong>Volunteering should not
        be capitalized upon by profit-seeking agencies.</strong> Even if an agency is labelled as an
        NGO, up to
        70% of your fees, charged as “overhead costs,” are distributed as salaries or as charitable
        donations (a vague and oftentimes misleading term) to people that are not directly involved
        with the legitimate philanthropic work that’s going on. <strong>The Campanile Project
        displays the
        same organizations listed on agency websites with full disclosure, and for free.</strong>
      </div>
      <div className="about-body-text about-mid-text">
        Furthermore, there are many volunteering programs that truly make a negligible impact in its
        community, or—even worse—may cause a detrimental effect on the local community as a result
        of volunteer reliance or malpractice within the volunteering organization. The Campanile
        Project strives to screen out such programs, so that prospective volunteers can be ensured
        that the work they’re doing will truly make a difference.
      </div>
      <div className="about-us-section">
        <div>
          <div className="about-subtitle">
            WHAT WE'RE ABOUT
          </div>
          <p className="about-body-text">
            {data.about_text}<strong> we are driven to make a lasting difference in the volunteering
            abroad industry.</strong>
          </p>
        </div>
        <img className="about-us-image" src={data.about_image} alt=""/>
      </div>
      <div style={{ marginTop: '50px' }}>
        <div className="about-subtitle">
          MEET OUR CO-FOUNDER:
        </div>
        <div className="about-body-text about-mid-text">
          I was inspired to start The Campanile Project as a result of my first time volunteering
          abroad when I was 16. I have no clue how I persuaded my parents at that time, but I
          managed to book a solo marine conservation trip in Koh Tao, Thailand.
        </div>
        <div className="about-body-text about-mid-text">
          When I arrived, I realized that my booking agency didn’t disclose the fact that I had to
          be over 18. On top of that, I paid over twice as much than the local organization—New
          Heaven Reef Conservation—charged its volunteers. I still had to arrange my own visa, pay
          for transportation, and undergo medical clearance with very minimal assistance. However,
          despite all this, the program organizers at New Heaven Reef Conservation made it an
          amazing experience once I was there. I was back the very next summer after booking
          directly through their own website.
        </div>
        <div className="about-body-text about-mid-text">
          During my two experiences there, I’ve talked with countless other volunteers regarding
          their prior placements, and I realized how lucky I was to have stumbled upon a
          conscientious, legitimate, and overall incredible program during my first time
          volunteering. I certainly walked away with an enhanced appreciation for sustainability and
          conservation, as well as amazing memories and lifelong friendships.
        </div>
        <div className="about-ning">
          <img src={data.ning_image} alt={'A Co-founder of The Campanile Project'}/>
          <div className="about-body-text">
            No volunteer should have to be “lucky” to encounter a responsible, well-matched program.
            I wanted volunteers to have full transparency on where and how they were going to
            volunteer before booking a trip. There were no widely accessible online resources for
            purely pre-screened, legitimate volunteering organizations. I hoped to change that.
          </div>
        </div>
        <div className="about-body-text about-mid-text">
          I grew up in Vancouver, Canada, and I’m {getNingSchoolYear()} the University
          of California, Berkeley. After arriving at college, I once again got lucky enough to meet
          my amazing, like-minded friends who were willing to share their skills to accomplish this
          project. In particular, I was fortunate enough to meet my co-founder & CTO—but, most
          importantly, my best friend—Shaylan. We immediately clicked through shared backpacking,
          travelling, and volunteering experiences. With his help and endless support, we created
          The Campanile Project. Coordinating this project throughout quarantine was definitely
          difficult, but we made it work with many in-between breaks to watch Avatar the Last
          Airbender over FaceTime.
        </div>
        <div className="about-body-text about-mid-text">
          Together—with the invaluable help of everyone else on board this project—we are thrilled
          to share this final product with you. We are so happy you’re here, and we’re excited to
          show you how volunteering should be done!
        </div>
      </div>
      {/*<Testimonial*/}
      {/*  name={*/}
      {/*    data*/}
      {/*      .testimonial1.name*/}
      {/*  }*/}
      {/*  text={*/}
      {/*    data*/}
      {/*      .testimonial1.text*/}
      {/*  }*/}
      {/*  image={*/}
      {/*    data*/}
      {/*      .testimonial1.image*/}
      {/*  }*/}
      {/*  align='left'/>*/}
      {/*<Testimonial*/}
      {/*  name={*/}
      {/*    data*/}
      {/*      .testimonial2.name*/}
      {/*  }*/}
      {/*  text={*/}
      {/*    data*/}
      {/*      .testimonial2.text*/}
      {/*  }*/}
      {/*  image={*/}
      {/*    data*/}
      {/*      .testimonial2.image*/}
      {/*  }*/}
      {/*  align='right'/>*/}
      {/*<Testimonial*/}
      {/*  name={*/}
      {/*    data*/}
      {/*      .testimonial3.name*/}
      {/*  }*/}
      {/*  text={*/}
      {/*    data*/}
      {/*      .testimonial3.text*/}
      {/*  }*/}
      {/*  image={*/}
      {/*    data*/}
      {/*      .testimonial3.image*/}
      {/*  }*/}
      {/*  align='left'/>*/}
      {/*<Testimonial*/}
      {/*  name={*/}
      {/*    data*/}
      {/*      .testimonial4.name*/}
      {/*  }*/}
      {/*  text={*/}
      {/*    data*/}
      {/*      .testimonial4.text*/}
      {/*  }*/}
      {/*  image={*/}
      {/*    data*/}
      {/*      .testimonial4.image*/}
      {/*  }*/}
      {/*  align='right'/>*/}
      <Contact/>
    </div>
  )
}
