import React from "react"

import "./HowTo.css"
import data from "./HowToData"
import Testimonial from "../../components/testimonials/Testimonial";

export default function HowTo() {
    return (
        <div className="flex-column howto-page">
            <div className="howto-top-container">
                <img className="howto-top-image"
                     src="/images/how-to/how-to-1.jpg" alt="Decoration"/>
                <div className="flex-column howto-top-text">
                    <p className="howto-title">HOW TO START?</p>
                    <div className="howto-body-text">
                      <p>{data.upper_description}</p>
                      <p>{data.upper_description_2}</p>
                    </div>
                </div>
            </div>
            {getLines()}
            <br />
            <Testimonial name={data.testimonial1.name}
                         text={data.testimonial1.text}
                         image={data.testimonial1.image}
                         align="left"/>
            <Testimonial name={data.testimonial2.name}
                         text={data.testimonial2.text}
                         image={data.testimonial2.image}
                         align="right"/>
            <div className="howto-bottom-container">
                <img className="howto-bottom-image"
                     src="/images/how-to/how-to-2.jpg" alt="Decoration"/>
                <div className="flex-column howto-bottom-info">
                    <p className="howto-body-text howto-bottom-text">{data.lower_description}</p>
                    <a className="howto-button download-checklist" href={data.checklist_link} download="checklist.pdf">
                        DOWNLOAD OUR CHECKLIST
                    </a>
                </div>
            </div>
        </div>
    )
}

function getLines() {

    const onClick = (link) => {
        window.open(link, "_self", "noopener=yes, noreferrer=yes")
    }

    return (
        <div>
            {data.lines.map(lineData => {
                return (
                    <div key={lineData.title} className="flex-column howto-lines">
                        <p className="howto-title">{lineData.title}</p>
                        <div className="howto-info">
                            <div className="howto-body-text howto-line-text">
                                {lineData.description}
                            </div>
                            <button className="howto-button read-more-button"
                                    onClick={onClick.bind(this, lineData.link)}>
                                READ MORE
                            </button>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
