import React from "react"
import PDFView from "../../components/pdf_view/PDFView";
import {useParams} from "react-router-dom"
import {S3_LINK} from "../../Constants";
import PDFJs from "../../components/pdf_view/pdfjs";
import "./PDFPage.css"

/**
 * Displays a PDF page linked to from the organization page using react router
 *
 * Usage: wrap in a Router component so that relative URL works:
 * <Router path="/orgs/:orgName/pdf">
 *    <PDFPage />
 * </Router>
 *
 * This will substitute the orgName in so that it can search S3 for the proper handbook PDF.
 * From there give redirects in the below relative URL format to go to the page for the relevant org
 * Relative URL: /org/:id/pdf
 *
 * Link Component can look like this:
 *    <Link to="/orgs/:orgName/pdf" params={{orgName: "testOrg"}}>PDF Test</Link>
 *
 * @param props
 * @constructor
 */
function PDFPage(props) {


    let {id} = useParams()
    id = encodeURIComponent(id)
    let url = encodeURI(`${S3_LINK}/orgs/${id}/handbook.pdf`)

    return (
        <div className={"pdf-page-container"}>
            <PDFView
                src={url}
                backend={PDFJs}
            />
        </div>
    )

}

export default PDFPage