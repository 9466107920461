import React from 'react'
import './App.css'
import HomePage from './pages/homepage/HomePage'
import Navbar from './components/navbar/Navbar'
import { Switch, Route } from 'react-router-dom'
import AdminPage from './pages/adminpage/AdminPage'
import PDFPage from './pages/pdfpage/PDFPage'
import SearchPage from './pages/searchpage/SearchPage'
import NotFound from './pages/NotFound'
import OrgPage from './pages/orgpage/OrgPage'
import HowTo from './pages/howtopage/HowTo'
import RegionPage from './pages/infopages/RegionPage'
import ActivityPage from './pages/infopages/ActivityPage'
import ExtendedHowTo from './pages/howtopage/ExtendedHowTo'
import AboutPage from './pages/aboutpage/AboutPage'

function App () {
  return (
    <main className="App-wrapper">
      <div className="App">
        <Switch>
          <Route exact path="/admin/" component={AdminPage}/>
          <Route path="/">
            <Navbar/>
            <Switch>
              <Route exact path="/">
                <HomePage/>
              </Route>
              <Route exact path="/region">
                <RegionPage/>
              </Route>
              <Route exact path="/activity">
                <ActivityPage/>
              </Route>
              <Route exact path="/how-to/:topic">
                <ExtendedHowTo/>
              </Route>
              <Route exact path="/how-to">
                <HowTo/>
              </Route>
              <Route exact path="/about">
                <AboutPage/>
              </Route>
              <Route exact path="/org/:id">
                <OrgPage/>
              </Route>
              <Route exact path="/org/:id/pdf">
                <PDFPage/>
              </Route>
              <Route path="/search/">
                <SearchPage/>
              </Route>
              <Route>
                <NotFound/>
              </Route>
            </Switch>
          </Route>
        </Switch>
      </div>
    </main>
);
}

export default App;
