import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './index.css'

export default function SearchBar (props) {
  const [value, setValue] = useState(props.value)

  const onSubmit = event => {
    event.preventDefault()
    props.onSubmit(value)
  }

  return (
    <div className="search-div">
      <link rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
      <form className="search-form" onSubmit={onSubmit}>
        <input value={value} onChange={event => {
          setValue(event.target.value)
        }} type="text" placeholder="Search.." className="search-input"/>
        <button type="submit" className="search-button"><i className="fa fa-search"></i></button>
      </form>
    </div>
  )
}

SearchBar.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string,
}
